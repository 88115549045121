@charset "UTF-8";

@font-face {
  font-family: "bullseye";
  src:url("/fonts/bullseye.eot");
  src:url("/fonts/bullseye.eot?#iefix") format("embedded-opentype"),
    url("/fonts/bullseye.woff") format("woff"),
    url("/fonts/bullseye.ttf") format("truetype"),
    url("/fonts/bullseye.svg#bullseye") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "bullseye" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="beye-"]:before,
[class*=" beye-"]:before {
  font-family: "bullseye" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.beye-rate-star:before {
  content: "\61";
}
.beye-tools:before {
  content: "\62";
}
.beye-gears:before {
  content: "\63";
}
